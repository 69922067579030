import { css } from "@emotion/react";
import { border, catYellow, catYellowHover, fontBody } from "./common.css";
import fonts from "./font.css";
import { fontBlack } from "../styles/common.css";

export default css`
  ${fonts}

  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  * {
    font-family: "Pretendard Variable", "SF Pro KR", "SF Pro Text",
      "SF Pro Icons", "AOS Icons", "Apple Gothic", "HY Gulim", "MalgunGothic",
      "HY Dotum", "Lexi Gulim", "Helvetica Neue", "Helvetica", "Arial",
      "sans-serif";
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }
  body {
    /* font-family: "Pretendard Variable"; */
    line-height: 1;
    font-size: 16px;
    /* overflow: hidden; */
  }

  h1,
  h2,
  h3 {
    line-height: 1.3em;
    color: ${fontBlack};
  }
  h4,
  h5 {
    line-height: 1.4em;
  }

  h6,
  p {
    line-height: 1.5em;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: "";
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
    color: ${fontBlack};
  }

  .contents {
    /* border-top: ${border}; */
  }

  footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 4px;
    margin: 40px 0 40px 0;
  }
  .icon-container {
    position: absolute;
    right: 10px;
    top: calc(50% - 10px);
  }
  .loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
  }

  @keyframes around {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loader::after,
  .loader::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #333 #333 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
  }

  .loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
  }

  input:focus {
    outline: none;
  }

  button {
    outline: none;
    background-color: inherit;
    border: none;
    cursor: pointer;
  }

  input:invalid {
    border-color: red;
  }

  .hidden {
    display: none;
  }

  #modal_root,
  #gallery_root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(210, 210, 210, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  .background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #eeeeee;
  }
  .product-item-footer {
    font-size: 12px;
    line-height: 2em;
  }

  /* form component */

  #__next {
    padding-bottom: 0;
  }

  #gaevaring {
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
  }

  /* 멘션시 드롭다운 */

  .ck-balloon-panel {
    box-shadow: 4px 8px 24px -8px rgb(98 108 114 / 9%) !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .ck-mentions {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
  }

  .ck-mentions::-webkit-scrollbar {
    display: none !important; /* Chrome, Safari, Opera*/
  }

  .mention-dropdown-item {
    width: 220px !important;
    padding: 10px 10px !important;
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;

    &.ck-on {
      background-color: ${catYellowHover} !important;
    }

    img {
      width: 40px !important;
      height: 40px !important;
      border-radius: 50% !important;
      margin-right: 10px !important;
      cursor: pointer !important;
    }

    .mention-dropdown-item-unique-id {
      font-family: "Pretendard Variable" !important;
      font-size: 14px !important;
      font-weight: 700 !important;
      color: ${fontBlack} !important;
      margin-right: 6px !important;
      line-height: 1 !important;
      cursor: pointer !important;
    }

    .mention-dropdown-item-name {
      font-family: "Pretendard Variable" !important;
      font-size: 14px !important;
      color: ${fontBody} !important;
      cursor: pointer !important;
    }
  }

  /* common css */
  .nonBorder {
    border: none !important;
  }
  /* common css */
`;
