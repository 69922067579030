import Script from "next/script";

import { Global } from "@emotion/react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RecoilRoot } from "recoil";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import globalStyles from "../styles/globals.css";

const queryClient = new QueryClient({});

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Script src="https://accounts.google.com/gsi/client" async defer />
      {/* <!-- Google Analytics tag (gtag.js) --> */}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-V1F9TZTVS2"
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          
          gtag('config', 'G-V1F9TZTVS2');`,
        }}
      />
      {/* Google Tag Manager */}
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-ML98ZGB');`,
        }}
      />

      {/* <!-- Microsoft Clarity --> */}
      <Script
        id="microsoft-clarity"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "f9zf8bnyjc");`,
        }}
      />

      {/* <!-- Crest Analytics --> */}
      <Script
        id="CrestAnalytics"
        src="https://crest.so/v0"
        data-domain="sofans.io"
        strategy="lazyOnload"
      />

      {/* <!-- Facebook Pixel Code --> */}
      <Script
        id="facebook-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
document,'script','https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '2504733349679173'); // Insert your pixel ID here.
fbq('track', 'PageView');
fbq('track', 'ViewContent');
fbq('track', 'CompleteRegistration');`,
        }}
      />

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=2504733349679173&ev=PageView&noscript=1"
        />
      </noscript>

      {/* <!-- Usetiful --> */}
      <Script
        id="usetiful"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
(function (w, d, s) {
    var a = d.getElementsByTagName('head')[0];
    var r = d.createElement('script');
    r.async = 1;
    r.src = s;
    r.setAttribute('id', 'usetifulScript');
    r.dataset.token = "fce81a85e7fd28274f6dca5ab27c97a2";
                        a.appendChild(r);
  })(window, document, "https://www.usetiful.com/dist/usetiful.js");`,
        }}
      />

      {/* Surffy tag */}
      {/* <Script
        id="surffy"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,uri,fn){
            w[fn] = w[fn] || function(){ var c = {}; c.tenantApiUrl = arguments[0]; c.tenantNFUrl = arguments[1]; (w[fn].l = w[fn].l || []).push(c); };
            var o = d.createElement(s); var p = d.getElementsByTagName(s)[0]; o.async = 1; o.charset = 'utf-8'; o.src = uri; p.parentNode.insertBefore(o,p);
            })(window,document,'script','https://agent-lib.surffy.io/surffy-agent-v2_latest.js','nfTag');
          nfTag('https://nf-setting.surffy.io/sofans/nf-setting.json', 'https://sofans.surffy.io');`,
        }}
      /> */}

      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <RecoilRoot>
            <Global styles={globalStyles} />
            <Component {...pageProps} />
          </RecoilRoot>
        </DndProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
