import { css } from "@emotion/css";

export const defaultBorderRadis = css`
  border-radius: 40px;
`;

/* Colors */
export const black = "#222930";
export const pri = "#000000";
export const catYellow = "#F18609";
export const fontBlack = "#222222";
export const BG_gray_02 = "#F9F9F9";
export const error = "#E8584F";
export const fontBody = "#777777";
export const gray_01 = "#EEEEEE";
export const white = "#FFFFFF";
export const blur_bg = "rgba(231, 237, 242, 0.5)";
export const catYellowHover = "rgba(241, 134, 9, 0.2)";
export const black06 = "rgba(0, 0, 0, 0.06)";
export const black30 = "rgba(0, 0, 0, 0.3)";
export const fontBodyLight = "rgba(136, 143, 156, 0.8)";
export const paperYellow = "rgba(249, 243, 238, 0.6)";
export const border = "1px solid rgba(0, 0, 0, 0.1)";
export const hoverBlue = "#D4E1EB";
export const catHover = "rgba(241, 134, 9, 0.2)";
export const listHover = "rgba(0, 0, 0, 0.9)";

export const wrap = "margin: 72px 120px 0px;";

export const boxHover = `
transition: .2s ease;
background-color: rgba(0, 0, 0, 0.04);
`;

export const layoutBorder = `
border: ${border};
border-radius: 6px;
`;

export const textBoxShadow = `
  box-shadow: 4px 8px 24px -8px rgba(98, 108, 114, 0.09);
`;

export const blackBtn = `
  background-color: ${fontBlack};
  border-radius: 40px;
`;

export const line2clamp = `
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const labelStyle = `
  font-weight: 700;
  font-size: 0.875em;
  color: ${fontBlack};
  margin-right: 20px;
  line-height:1.5em;
`;
